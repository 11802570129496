// extracted by mini-css-extract-plugin
export var imageAnimationEnd = "index-module--image-animation-end--auYKB";
export var imageAnimationStart = "index-module--image-animation-start--m1md-";
export var imageCaption = "index-module--image-caption--eIyQz";
export var imageItem = "index-module--image-item--OveZm";
export var imageSection = "index-module--image-section--1jXAj";
export var picGalleryFigure = "index-module--pic-gallery-figure--aT1hY";
export var picGalleryImage = "index-module--pic-gallery-image--6Qgyt";
export var readMoreButton = "index-module--read-more-button--CLYGI";
export var readMoreContainer = "index-module--read-more-container--tH6qA";
export var salonImagesContainer = "index-module--salon-images-container--f-f96";
export var sloganText = "index-module--slogan-text--4mgAj";
export var socialsText = "index-module--socials-text--isR5H";
export var videoAnimationEnd = "index-module--video-animation-end--2BBba";
export var videoAnimationStart = "index-module--video-animation-start--sWC0O";
export var videoContainer = "index-module--video-container--8wd2L";