
import * as styles from './gallery.module.scss';
import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../../data/defaultPixelDensities";

const Gallery = () => {
    const employees = [
        {
          name: 'Benedikte',
          image: <StaticImage
              src={"../../images/employees/benedikte-1500-4.jpg"}
              alt={"Benedikte"}
              width={240}
              className={styles.galleryImage}
              sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
              outputPixelDensities={outputPixelDensities}
          />
        },
        {
            name: 'Jasmin',
            image: <StaticImage
                src={"../../images/employees/jasmin-1500.jpg"}
                alt={"Jasmin"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        },
        {
            name: 'Dayanis',
            image: <StaticImage
                src={"../../images/employees/dayanis.jpg"}
                alt={"Dayanis"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        },
        {
            name: 'Patricia',
            image: <StaticImage
                src={"../../images/employees/patricia4.jpg"}
                alt={"Patricia"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        },
        {
            name: 'Isabel',
            image: <StaticImage
                src={"../../images/employees/isabel3.jpg"}
                alt={"Isabel"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        },
        {
            name: 'Aga',
            image: <StaticImage
                src={"../../images/employees/aga3.jpg"}
                alt={"Aga"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        },
        {
            name: 'Celine',
            image: <StaticImage
                src={"../../images/employees/celine.jpg"}
                alt={"Celine"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        },
        {
            name: 'Tina',
            image: <StaticImage
                src={"../../images/employees/tina.jpg"}
                alt={"Tiril"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        },
        {
            name: 'Tiril',
            image: <StaticImage
                src={"../../images/employees/tiril.jpg"}
                alt={"Tiril"}
                width={240}
                className={styles.galleryImage}
                sizes={"(min-width: 1280px) 220px, (min-width: 1000px) 220px, (min-width: 690px) calc((100vw - 64px) / 4), calc((((100vw - 40px) + 1rem) / 3) - 0.6rem)"}
                outputPixelDensities={outputPixelDensities}
            />
        }
    ];

    return (
        <div className={styles.gallery}>
            {
                employees.map(employee => (
                    <div className={styles.galleryItem} key={employee.name}>
                        <figure className={styles.figure}>
                            { employee.image }
                            <figcaption>
                                <div className={styles.caption}>{ employee.name }</div>
                            </figcaption>
                        </figure>
                    </div>
                ))
            }
        </div>
    )
};

export default Gallery;