import * as grid from '../styles/grid.module.scss';
import * as styles from '../styles/index.module.scss';
import Banner from "../components/banner/Banner";
import ContentMargins from "../components/layout/ContentMargins";
import Gallery from "../components/gallery/Gallery";
import Layout from "../components/layout/layout"
import LightboxGallery from "../components/LightboxGallery/LightboxGallery";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import SeeAvailableButton from "../components/button/SeeAvailableButton";
import Seo from "../components/Seo"
import { StaticImage } from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";
import {Link} from "gatsby";


const IndexPage = () => {
    const pics = [
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/1.jpg"}
                alt={"Vippeløft"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Vippeløft"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/2.jpg"}
                alt={"Klassiske Vippeextensions"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Klassiske Vippeextensions"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/3.jpg"}
                alt={"Klassiske Vippeextensions"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Klassiske Vippeextensions"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/4.jpg"}
                alt={"Klassiske Vippeextensions"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Klassiske Vippeextensions"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/5.jpg"}
                alt={"Naturlig Volum"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Naturlig Volum"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/6.jpg"}
                alt={"Dramatisk Volum"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Dramatisk Volum"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/7.jpg"}
                alt={"Klassiske Vippeextensions"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Klassiske Vippeextensions"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/8.jpg"}
                alt={"Vippeløft"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Vippeløft"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/9.jpg"}
                alt={"Dramatisk Volum"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Dramatisk Volum"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/10.jpg"}
                alt={"Klassiske Vippeextensions"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Klassiske Vippeextensions"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/11.jpg"}
                alt={"Vippeløft"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Vippeløft"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/12.jpg"}
                alt={"Farge-vippeextensions"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Farge-vippeextensions"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/13.jpg"}
                alt={"Klassiske Vippeextensions"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Klassiske Vippeextensions"
        },
        {
            component: <StaticImage
                src={"../images/frontpage/gallery/14.jpg"}
                alt={"Dramatisk Volum"}
                className={styles.picGalleryImage}
                sizes={"(min-width: 960px) 289px, (min-width: 600px) calc(((100vw - 64px) / 2) - 16px), calc(((100vw - 40px) / 2) - 16px)"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            label: "Dramatisk Volum"
        }
    ];

    // Video
    const [pageLoaded, setPageLoaded] = useState(false);
    useEffect(() => {
        if (document.readyState === 'complete'){
            setPageLoaded(true);
        } else {
            const onPageLoad = () => {
                if (document.readyState === 'complete'){
                    setPageLoaded(true);
                }
            }

            document.addEventListener('readystatechange', onPageLoad);
            return () => {
                document.removeEventListener('readystatechange', onPageLoad);
            };
        }
    }, []);

    const [videoLoaded, setVideoLoaded] = useState(false);
    const setVideoRef = useCallback(videoDOM => {
        if (!videoDOM){return;}

        if (videoDOM.readyState === 4){
            setVideoLoaded(true);
        } else {
            const listener = () => {
                setVideoLoaded(true);
                videoDOM.removeEventListener("canplaythrough", listener);
            }

            videoDOM.addEventListener('canplaythrough', listener);
        }
    }, []);

    const videoElement = useMemo(() => {
        if (!pageLoaded){return null;}

        return(
            <video
                width={"720"}
                height={"404"}
                loop
                autoPlay
                muted
                playsInline
                className={styles.videoAnimationStart + (videoLoaded ? ` ${styles.videoAnimationEnd}` : "")}
                src={'/background_video/bv5.mp4'}
                ref={setVideoRef}
            />
        )
    }, [pageLoaded, videoLoaded, setVideoRef]);

    // Lightbox
    const openLightbox = useRef(null);

    const openLightboxClick = useCallback(event => {
        const index = event.currentTarget.getAttribute("data-gallery-index");
        openLightbox.current(parseInt(index, 10));
    }, []);

    const openLightboxEnter = useCallback(event => {
        if (event.key === "Enter"){
            const index = event.currentTarget.getAttribute("data-gallery-index");
            openLightbox.current(parseInt(index, 10));
        }
    }, []);

    const picGallery = pics.map((pic, index) => (
        <div
            className={`${grid.xs6Md4P8}`}
            key={`gallery_${index}`}
            data-gallery-index={index}
            onClick={openLightboxClick}
            onKeyDown={openLightboxEnter}
            role={"button"}
            tabIndex={0}
        >
            <figure className={styles.picGalleryFigure}>
                { pic.component }
                <figcaption>
                    <div className={styles.imageCaption}>
                        { pic.label }
                    </div>
                </figcaption>
            </figure>
        </div>
    ));

    return (
        <Layout>
            <Seo title="Forside"/>
            <Banner/>
            <ContentMargins>
                <div className={styles.readMoreContainer}>
                    <Link to={'/landing_vippeloft'} className={styles.readMoreButton}>Les om<br/>vippeløft</Link>
                    <Link to={'/landing_vippeextensions'} className={styles.readMoreButton}>Les om<br/>vippeextensions</Link>
                </div>
                <div className={styles.videoContainer}>
                    <div className={styles.imageAnimationStart + (videoLoaded ? ` ${styles.imageAnimationEnd}` : "")}>
                        <StaticImage
                            src={"../images/frontpage/benedikte-vippeextensions.jpg"}
                            alt={"Utsiden av Benedikte Vippeextensions Grünerløkka"}
                            width={1920}
                            sizes={"(max-width: 900px) 50vw, 100vw"}
                            outputPixelDensities={outputPixelDensities}
                            quality={40}
                        />
                    </div>
                    { videoElement }
                </div>
                <section>
                    <Gallery/>
                </section>
                <section>
                    <p className={styles.sloganText}>
                        Vi er spesialister på vippeextensions og vippeløft. Vi har et brennende engasjement i faget, og holder oss alltid oppdatert på nye teknikker og ny kunnskap. Hos oss er du trygg på at du får en hyggelig og trygg behandling, og vakre vipper som du er fornøyd med! Vi analyserer dine naturlige vipper og ditt ansikt for å gi deg det beste designet innen vippeextensions, og vi legger aldri extensions som er skadelige for dine egne vipper. Kom gjerne med spørsmål til oss, vi er her for deg!
                    </p>
                    <SeeAvailableButton>
                        Se priser og ledige timer
                    </SeeAvailableButton>
                </section>
                {/*<section>*/}
                {/*    Her kommer bilder */}
                {/*</section>*/}
                <section>
                    <p className={styles.socialsText}>{"Se bilder av arbeidet vårt på vår "}
                        <a href={'https://www.instagram.com/benediktevipper/'} target={'_blank'}
                           rel={'noopener noreferrer'}>instagram</a>
                        {" eller "}
                        <a href={'https://www.facebook.com/benediktevippeextensions/'} target={'_blank'}
                           rel={'noopener noreferrer'}>facebook</a>-side.
                    </p>
                </section>
                <section>
                    <div className={grid.container}>
                        { picGallery }
                    </div>
                    <LightboxGallery openRef={openLightbox} images={pics} />
                </section>
            </ContentMargins>
        </Layout>
    );
};

export default IndexPage;