import * as styles from "./lightbox-gallery.module.scss";
import LeftCaret from "@material-ui/icons/KeyboardArrowLeft"
import React, {useCallback, useEffect, useState} from 'react';
import RightCaret from "@material-ui/icons/KeyboardArrowRight"

const LightboxGallery = ({ images, openRef }) => {
    const [open, setOpen] = useState(null);

    openRef.current = useCallback(index => {
        setOpen(index);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(null);
    }, []);

    const handleCloseEscape = useCallback(event => {
        if (event.key === "Escape"){
            setOpen(null);
        }
    }, []);

    const next = useCallback(event => {
        setOpen(prev => {
            if (open === images.length - 1){
                return 0;
            } else {
                return open + 1;
            }
        });

        event.stopPropagation();
        return false;
    }, [open, images]);

    const previous = useCallback(event => {
        setOpen(prev => {
            if (open === 0){
                return images.length - 1;
            } else {
                return open - 1;
            }
        });

        event.stopPropagation();
        return false;
    }, [open, images]);

    const stopPropagation = useCallback(event => {
        event.stopPropagation();
        return false;
    }, []);

    const [fullSizeImages, setFullSizeImages] = useState(null);
    useEffect(() => {
        if (open === null){return null;}

        (async () => {
            const req = await import("./LightboxGalleryFullSizeImages");
            setFullSizeImages(req.default);
        })();
    }, [open]);

    if (open === null){return null;}

    return(
        <div
            className={styles.lightboxGallery}
            onClick={handleClose}
            onKeyDown={handleCloseEscape}
            role={"button"}
        >
            <div className={styles.lightboxCaretLandscape}>
                <div className={styles.lightboxCaret} onClick={previous}>
                    <LeftCaret/>
                </div>
            </div>
            <div className={styles.lightboxImage} onClick={stopPropagation}>
                {
                    fullSizeImages ? fullSizeImages[open].component : null
                }
                <div className={styles.lightboxCaretPortrait}>
                    <div className={styles.lightboxCaret} onClick={previous}><LeftCaret/></div>
                    <div className={styles.lightboxCaptionPortrait}>
                        { images[open].label }<br/>
                        { `${open + 1}/${images.length}` }
                    </div>
                    <div className={styles.lightboxCaret} onClick={next}><RightCaret/></div>
                </div>
                <div className={styles.lightboxCaptionLandscape}>{ images[open].label }</div>
            </div>
            <div className={styles.lightboxCaretLandscape} onClick={next}>
                <div className={styles.lightboxCaret}><RightCaret/></div>
            </div>
        </div>
    )
};

export default LightboxGallery;